import React from "react"
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Logo from 'img/logo.svg';

function Main() {
    return (
        <>
            <Helmet>
              <title>294</title>
            </Helmet>
            
            <div className="background">

                <div className="main-wrapper">
                    <Link to="/" className="logo">
                        <img src={Logo} alt="294 Logo" />
                    </Link>

                    <div className="title-wrapper">
                        <h1 className="title">2것저것 294</h1>
                        <h3 className="subtitle">메인페이지 작업중</h3>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Main;