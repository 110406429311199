import React, { useEffect, useRef, useCallback } from "react";
import gsap from "gsap";

const Transition = ({ direction, onComplete, onFinishLoading }) => {
  const overlayRef = useRef([]);

  const removeOverlay = useCallback(() => {
    const lastOverlay = overlayRef.current[overlayRef.current.length - 1];

    if (!lastOverlay) return;

    const moveProp =
      direction === "top" || direction === "bottom"
        ? direction === "top"
          ? { top: "100%" }
          : { bottom: "100%" }
        : direction === "left"
        ? { left: "100%" }
        : { right: "100%" };

    gsap.to(lastOverlay, {
      ...moveProp,
      duration: 0.3,
      ease: "circ.inOut",
      onComplete: onFinishLoading,
    });
  }, [direction, onFinishLoading]);

  useEffect(() => {
    if (direction) {
      const overlays = overlayRef.current;

      overlays.forEach((overlay, index) => {
        gsap.set(overlay, {
          position: "fixed",
          zIndex: 9999,
          width: direction === "left" || direction === "right" ? "0%" : "100%",
          height: direction === "top" || direction === "bottom" ? "0%" : "100%",
          top: direction === "top" ? "0%" : "auto",
          bottom: direction === "bottom" ? "0%" : "auto",
          left: direction === "left" ? "0%" : "auto",
          right: direction === "right" ? "0%" : "auto",
        });
      });

      const timeline = gsap.timeline({
        onComplete,
      });

      overlays.forEach((overlay, index) => {
        timeline.to(
          overlay,
          {
            [direction === "top" || direction === "bottom" ? "height" : "width"]: "100%",
            duration: 0.2,
            ease: "circ.inOut",
            delay: index * 0.1,
          },
          "<"
        );
      });

      timeline.set(
        overlays.slice(0, overlays.length - 1),
        { visibility: "hidden" },
        "+=0.4"
      );

      timeline.set(overlays[overlays.length - 1], {
        zIndex: 10000,
      });

      timeline.call(() => removeOverlay());
    }
  }, [direction, onComplete, removeOverlay]);

  return (
    <>
      {[1, 2, 3, 4].map((_, index) => (
        <div
          key={index}
          ref={(el) => (overlayRef.current[index] = el)}
          className={`transition transition-rect-${index + 1}`}
        ></div>
      ))}
    </>
  );
};

export default Transition;
