import React from "react"
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet-async";
import Logo from '../img/logo.svg';

const Error = () => {
    return (
        <>
            <Helmet>
              <title>오류</title>
              <meta property="og:title" content="294 - 오류" />
            </Helmet>
            <div className="error-page">
              <div className="logo-no-animation">
                <img src={Logo} alt="294 Logo" />
              </div>

              <h2 className="error-heading">404 Not Found</h2>
              <p className="error-subheading">
                저런, 잘못된 접근이거나 혹은 서버가 꺼져있나봐요. <br />
              </p>
              <span className="error-paragraph">여기, 버튼을 하나 준비했어요.</span>
              <Link to="/" className="error-button">
                <span>
                  <i className="fas fa-home-lg"></i>돌아가기
                </span>
              </Link>
            </div>
        </>
    );
}
export default Error;