import React, { useState } from "react"
import { Helmet } from "react-helmet-async";
import worksData from "core/works.json";

function Main() {
    const [hoveredWorkId, setHoveredWorkId] = useState(null);
    const [loadingVideos, setLoadingVideos] = useState({});

    const linesCount = 3;
    const worksLines = Array.from({ length: linesCount }, () => []);
    const placeholderImage = "https://via.placeholder.com/300x200?text=No+Image"; // 플레이스홀더 URL

    const getRandomGradient = () => {
        const colors = [
            "#FF9E9E",
            "#A4E57E",
            "#AFA4FF",
            "#FFE7A4",
            "#DFFFD6",
            "#FFB3B8",
            "#D1A5BF",
            "#BFA7C6"
        ];
        const color1 = colors[Math.floor(Math.random() * colors.length)];
        const color2 = colors[Math.floor(Math.random() * colors.length)];
        return `linear-gradient(135deg, ${color1}, ${color2})`;
    };
    
    const handleVideoLoaded = (id) => {
        setLoadingVideos((prev) => ({ ...prev, [id]: false }));
    };

    const handleVideoError = (e, id) => {
        const videoElement = e.target;
        const fallbackImage = videoElement.getAttribute("data-thumb");
        setLoadingVideos((prev) => ({ ...prev, [id]: false })); // 로딩 상태 해제
        videoElement.outerHTML = `<img src="${fallbackImage}" alt="Fallback" class="work-image" onError="this.src='${placeholderImage}'"/>`;
    };

    worksData.sort((a, b) => b.year - a.year);

    worksData.forEach((work, index) => {
        const lineIndex = index % linesCount;
        worksLines[lineIndex].push(work);
    });

    return (
        <>
            <Helmet>
              <title>294 - Works</title>
            </Helmet>
            
            <section id="works">

                <main id="works-list">
                    <div
                        className={`works-list-grid ${hoveredWorkId ? "dimmed" : ""}`}
                    >
                    {worksLines.map((line, lineIndex) => (
                        <div className="works-line" key={lineIndex}>
                            {line.map((work) => (
                                <div
                                    className={`works ${
                                        hoveredWorkId === work.id ? "active" : ""
                                    }`}
                                    key={work.id}
                                    onMouseEnter={() => setHoveredWorkId(work.id)}
                                    onMouseLeave={() => setHoveredWorkId(null)}
                                >
                                    <div
                                        className="work-video-container"
                                        style={{
                                            background: loadingVideos[work.id]
                                                ? getRandomGradient()
                                                : "transparent",
                                            filter: loadingVideos[work.id] ? "blur(10px)" : "none",
                                            transition: "filter 0.3s ease, background 0.3s ease",
                                        }}
                                    >
                                        {work.videoUrl ? (
                                            <video
                                                className="work-video"
                                                autoPlay
                                                loop
                                                muted
                                                playsInline
                                                data-thumb={work.thumbUrl}
                                                onLoadedData={() => handleVideoLoaded(work.id)}
                                                onError={(e) => handleVideoError(e, work.id)}
                                                onLoadStart={() =>
                                                    setLoadingVideos((prev) => ({
                                                        ...prev,
                                                        [work.id]: true,
                                                    }))
                                                }
                                            >
                                                <source src={work.videoUrl} type="video/mp4" />
                                            </video>
                                        ) : work.thumbUrl ? (
                                            <img
                                                src={work.thumbUrl}
                                                alt={work.title}
                                                className="work-image"
                                                onLoad={() => handleVideoLoaded(work.id)}
                                                onError={(e) =>
                                                    (e.target.src = placeholderImage)
                                                }
                                            />
                                        ) : (
                                            <img
                                                src={placeholderImage}
                                                alt="No content available"
                                                className="work-image"
                                                onLoad={() => handleVideoLoaded(work.id)}
                                            />
                                        )}
                                    </div>

                                    <div className="work-title">{work.title}</div>
                                    <div className="work-year">{work.year}</div>

                                    <div className="gradient-bg"></div>
                                    {hoveredWorkId === work.id && (
                                        <>
                                            <div className="work-icons drop-icon">
                                                {work.type !== "none" && (
                                                    <p className="work-type" id={work.type}>
                                                        {work.type}
                                                    </p>
                                                )}
                                                {work.repoUrl !== "none" && (
                                                    <a
                                                        href={work.repoUrl}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        aria-label="GitHub Repository"
                                                        className="work-icon github drop-icon"
                                                    >
                                                        <i className="fab github fa-github"></i>
                                                    </a>
                                                )}
                                                {work.previewUrl !== "none" && (
                                                    <a
                                                        href={work.previewUrl}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        aria-label="Preview"
                                                        className="work-icon link drop-icon"
                                                    >
                                                        <i className="far fa-link"></i>
                                                    </a>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                    ))}
                    </div>
                </main>
            </section>

        </>
    );
}

export default Main;

