import React from "react"
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import Logo from 'img/logo.svg';

function Main() {

    return (
        <>
            <Helmet>
              <title>294 - 방명록</title>
            </Helmet>
            
            <div className="background">
                <div className="main-wrapper">
                    <Link to="/" className="logo">
                        <img src={Logo} alt="294 Logo" />
                    </Link>

                    <div className="title-wrapper">
                        <h1 className="title">방명록</h1>
                        <h3 className="subtitle">현재 작업중이에요</h3>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Main;